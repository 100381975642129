@import "~antd/dist/antd.css";

@font-face {
  font-family: Sommet;
  src: url("./fonts/Sommet.otf") format("opentype");
}

.landing-page {
  color: floralwhite;
  font-family: "Sommet";
}

.group-table .ant-table-body {
  overflow-x: scroll;
}

.admin-graphs {
  background: rgb(243, 243, 243);
  border: 1px solid rgb(226, 226, 226);
  padding-left: 10px;
  padding-right: 10px;
}